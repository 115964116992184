import React from 'react';
import {
  Route,
  Switch,
  Redirect,
  useLocation,
} from 'react-router-dom';
import Login from '../views/Login/Login';
import Forbidden from '../views/Forbidden/Forbidden';
import ProtectedRoute from '../helpers/ProtectedRoute';
import ComplianceRoutes from './complianceRoutes';
import Dashboard from '../views/Dashboard/Dashboard';
import UpdateContractOwner from '../views/UpdateContractOwner/UpdateContractOwner';
import Health from '../views/Health/Health';
import CognitoUserConfirmation from '../views/CognitoUserConfirmation/CognitoUserConfirmation';
import EnterpriseManagement from '../views/EnterpriseManagement/EnterpriseManagement';
import Invoices from '../views/Invoices/Invoices';
import CustomerListRoutes from './customerListRoutes';
import EnterprisesRoutes from './enterprisesRoutes';
import BroadcastRoutes from './broadcastRoutes';
import AiComplianceChecker from '../views/AiComplianceChecker/AiComplianceChecker';
import StatsRoutes from './statsRoutes';
import GroupsRoutes from './groupsRoutes';

const Routes = () => {
  const { pathname } = useLocation();

  return (
    <Switch>
      {/* Avoid problems with double trailing slash */}
      <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
      <Route path="/" exact>
        <Login />
      </Route>
      <Route path="/dashboard" exact>
        <Dashboard />
        {/* <Redirect to="/compliance" /> */}
      </Route>
      <Route path="/compliance">
        <ComplianceRoutes />
      </Route>
      <Route path="/customers">
        <CustomerListRoutes />
      </Route>
      <Route path="/contract" exact>
        <UpdateContractOwner />
      </Route>
      <Route path="/enterprises">
        <EnterprisesRoutes />
      </Route>
      <Route path="/groups">
        <GroupsRoutes />
      </Route>
      <ProtectedRoute path="/ai_compliance_checker" exact>
        <AiComplianceChecker />
      </ProtectedRoute>
      <ProtectedRoute path="/users/enterprises" exact>
        <Redirect to="/users" />
      </ProtectedRoute>
      <ProtectedRoute path="/users/confirmation" exact>
        <Redirect to="/users" />
      </ProtectedRoute>
      <ProtectedRoute path="/users" exact>
        <CognitoUserConfirmation />
      </ProtectedRoute>
      <ProtectedRoute path="/enterprise" exact>
        <EnterpriseManagement />
      </ProtectedRoute>
      <ProtectedRoute path="/invoices" exact>
        <Invoices />
      </ProtectedRoute>
      <ProtectedRoute path="/home" exact>
        <Dashboard />
      </ProtectedRoute>
      <ProtectedRoute path="/forbidden" exact>
        <Forbidden />
      </ProtectedRoute>
      <ProtectedRoute path="/healths" exact>
        <Health />
      </ProtectedRoute>
      <ProtectedRoute path="/stats">
        <StatsRoutes />
      </ProtectedRoute>
      <Route path="/broadcast">
        <BroadcastRoutes />
      </Route>
    </Switch>
  );
};

export default Routes;
